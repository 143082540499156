import type { App } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/views/Layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/Dashboard/index.vue'),
      meta: {
        title: '首页',
        affix: true
      }
    }]
  }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 路由跳转后滚动条位置
  scrollBehavior: () => ({ left: 0, top: 0 })
})

// config router
export function setupRouter(app: App<Element>) {
  app.use(router)
}
