<template>
  <div class="layout-wrap">
    <div class="header-wrap">
      <img src="../../assets/logo.png" class="icon"/>
      <div class="right-menu-wrap">
        <div class="menu-item">首页</div>
        <div class="menu-item">产品介绍</div>
        <div class="menu-item">关于我们</div>
        <div class="menu-item">联系我们</div>
        <div class="menu-btn">我要合作</div>
      </div>
    </div>
    <div class="body-wrap">
      <router-view/>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.layout-wrap{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header-wrap{
    width: 100%;
    height: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    .icon{
      margin-left: 360px;
      width: 141px;
      height: 48px;
    }
    .right-menu-wrap{
      margin-left: 500px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
      .menu-item{
        cursor: pointer;
        color: #333333;
      }
      .menu-btn{
        cursor: pointer;
        width: 108px;
        height: 36px;
        background: #44D1B5;
        border-radius: 6px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
  .body-wrap{
    flex: 1;
    width: 100%;
    overflow: auto;
  }
}
</style>
